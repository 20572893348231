import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

const Slider = {
    init() {
        var swipers = document.querySelectorAll('.swiper-container');

        swipers.forEach((container) => {
            var swiper = container.querySelector('.swiper');
            swiper.classList.remove('hidden');
            var slidePerView = swiper.hasAttribute('data-slides-per-view') ? parseInt(swiper.getAttribute('data-slides-per-view')) : 1;

            var options = {
                modules: [Navigation, Pagination, EffectFade, Autoplay],
                speed: swiper.hasAttribute('data-speed') ? parseInt(swiper.getAttribute('data-speed')) : 1000,
                loop: swiper.hasAttribute('data-loop'),
                grabCursor: swiper.hasAttribute('data-grab-cursor'),
                slidesPerGroup: slidePerView,
                spaceBetween: 32,
            };

            if (slidePerView !== 1) {
                options.breakpoints = {
                    320: {
                        slidesPerView: 1,
                    },
                    480: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 3,
                    },
                    1100: {
                        slidesPerView: slidePerView,
                    },
                };
            }

            // Enable pagination
            if (swiper.hasAttribute('data-pagination')) {
                let color = swiper.getAttribute('data-navigation-color');
                options.pagination = {
                    el: container.querySelector('.swiper-pagination'),
                    clickable: true,
                    bulletClass: 'bullet border-2 w-4 h-4 rounded-pill block hover:bg-' + color + ' hover:border-' + color + ' hover:cursor-pointer',
                    bulletActiveClass: 'bullet-active bg-' + color + ' border-' + color,
                };
            }

            // Enable fade
            if (swiper.hasAttribute('data-fade')) {
                options.effect = 'fade';
                options.slidePerView = 6;
                options.fadeEffect = {
                    crossFade: true,
                };
            }

            // Enable autoplay
            if (swiper.hasAttribute('data-autoplay') && swiper.getAttribute('data-autoplay')) {
                options.autoplay = {
                    delay: swiper.getAttribute('data-autoplay'),
                };
            }

            // Enable continuous play
            if (swiper.hasAttribute('data-continuous')) {
                swiper.querySelector('.swiper-wrapper').style.transitionTimingFunction = 'linear';
                options.autoplay = {
                    delay: 1,
                    disableOnInteraction: false,
                };
                options.slidesPerGroup = 1;
            }

            // Enable navigation
            if (swiper.hasAttribute('data-navigation')) {
                container.querySelector('.arrow-prev').addEventListener('click', function () {
                    swiperInstance.slidePrev(options.speed);
                });
                container.querySelector('.arrow-next').addEventListener('click', function () {
                    swiperInstance.slideNext(options.speed);
                });
            }

            const swiperInstance = new Swiper(swiper, options);
        });
    },
};

Slider.init();
